// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Csrf from "../Csrf.res.js";
import * as Http from "../Http.res.js";
import * as $$Promise from "../../bindings/Promise.res.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";

function handleResponse(response, json) {
  return [
          "Fetch::RequestError",
          [
            "code",
            response.status
          ],
          [
            "status",
            response.statusText
          ],
          [
            "contentType",
            "json"
          ],
          [
            "responseBody",
            json
          ]
        ];
}

function exec(input) {
  var formData = new FormData();
  formData.append("user[email]", input.email);
  return $$Promise.andThen(fetch("/users/password", {
                  method: Http.Method.toFetch({
                        NAME: "Post",
                        VAL: undefined
                      }),
                  body: Caml_option.some(formData),
                  headers: Caml_option.some(new Headers([[
                              "X-CSRF-Token",
                              Belt_Option.getExn(Csrf.token())
                            ]])),
                  credentials: "same-origin"
                }), (function (response) {
                if (response.ok) {
                  return $$Promise.resolve({
                              TAG: "Ok",
                              _0: undefined
                            });
                }
                var match = Http.ContentType.fromHeaders(response.headers);
                if (match === undefined) {
                  return $$Promise.resolve({
                              TAG: "Error",
                              _0: undefined
                            });
                }
                if (typeof match === "object") {
                  return $$Promise.resolve({
                              TAG: "Error",
                              _0: undefined
                            });
                }
                switch (match) {
                  case "Text" :
                      return $$Promise.map(response.text(), (function (param) {
                                    return {
                                            TAG: "Error",
                                            _0: undefined
                                          };
                                  }));
                  case "Json" :
                      return $$Promise.map(response.json(), (function (param) {
                                    return {
                                            TAG: "Error",
                                            _0: undefined
                                          };
                                  }));
                  case "Blob" :
                      return $$Promise.map(response.blob(), (function (param) {
                                    return {
                                            TAG: "Error",
                                            _0: undefined
                                          };
                                  }));
                  
                }
              }));
}

var $$FormData$1;

export {
  $$FormData$1 as $$FormData,
  handleResponse ,
  exec ,
}
/* Csrf Not a pure module */
