// extracted by mini-css-extract-plugin
export var column = "SortingPopover__column__T7yvj";
export var container = "SortingPopover__container__qylsE";
export var divider = "SortingPopover__divider__kRbhL";
export var flex = "SortingPopover__flex__QUxnC";
export var flexColumn = "SortingPopover__flexColumn__QIhT4";
export var gap1 = "SortingPopover__gap1__BKs8g";
export var gap2 = "SortingPopover__gap2__VtCnw";
export var gap3 = "SortingPopover__gap3__gdU4U";
export var gap4 = "SortingPopover__gap4__Ap29l";
export var gap5 = "SortingPopover__gap5__W6_ih";
export var popup = "SortingPopover__popup__yJzwZ";
export var positionBottom = "SortingPopover__positionBottom__wUNMl";
export var row = "SortingPopover__row__gr57S";
export var sortSelection = "SortingPopover__sortSelection__xM3Ai";
export var sortingPopover = "SortingPopover__sortingPopover__f9SOy";
export var trigger = "SortingPopover__trigger__F351h";